import shortid from "shortid";

export const SIDEBAR_ITEM = "sidebarItem";
export const ROW = "row";
export const COLUMN = "column";
export const COMPONENT = "component";

export const SIDEBAR_ITEMS = [
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă pătrată 2 persoane",
      content: "",
      icon: require("./svg/square-2.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă pătrată 2 persoane",
      content: "",
      icon: require("./svg/square-2-h.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă pătrată 4 persoane",
      content: "",
      icon: require("./svg/square-4.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă pătrată 6 persoane",
      content: "",
      icon: require("./svg/square-6.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă pătrată 8 persoane",
      content: "",
      icon: require("./svg/square-8.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă rotundă 2 persoane",
      content: "",
      icon: require("./svg/circle-2.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă rotundă 2 persoane",
      content: "",
      icon: require("./svg/circle-2-1.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă rotundă 4 persoane",
      content: "",
      icon: require("./svg/circle-4.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă rotundă 6 persoane",
      content: "",
      icon: require("./svg/circle-6.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă rotundă 8 persoane",
      content: "",
      icon: require("./svg/circle-8.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă dreptunghiulară 6 persoane",
      content: "",
      icon: require("./svg/rect-6.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă dreptunghiulară 6 persoane",
      content: "",
      icon: require("./svg/rect-6-1.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă dreptunghiulară 8 persoane",
      content: "",
      icon: require("./svg/rect-8.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Masă dreptunghiulară 8 persoane",
      content: "",
      icon: require("./svg/rect-8-1.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Bar",
      content: "",
      icon: require("./svg/bar-1.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Bar",
      content: "",
      icon: require("./svg/bar-2.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Bar",
      content: "",
      icon: require("./svg/bar-3.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Bar",
      content: "",
      icon: require("./svg/bar-4.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Spațiu gol",
      content: "",
      icon: require("./svg/empty.svg")
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Ornament",
      content: "",
      icon: require("./svg/flower.svg")
    }
  },
  
  
];
