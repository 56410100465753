import { COMPONENT, ROW, COLUMN } from "./constants";

const initialData = {
  layout: [
    {
      type: ROW,
      id: "row0",
      children: [
        {
          type: COLUMN,
          id: "column0",
          children: [
            {
              type: COMPONENT,
              id: "component0"
            },
            {
              type: COMPONENT,
              id: "component1"
            }
          ]
        },
        {
          type: COLUMN,
          id: "column1",
          children: [
            {
              type: COMPONENT,
              id: "component2"
            }
          ]
        }
      ]
    },
  ],
  components: {
    component0: { 
      id: "component0", 
      type: "Masă pătrată 2 persoane",
      content: "",
      icon: require("./svg/square-2.svg")
    },
    component1: { 
      id: "component1", 
      type: "Masă pătrată 4 persoane",
      content: "",
      icon: require("./svg/square-4.svg")
      
    },
    component2: { 
      id: "component2", 
      type: "Masă pătrată 6 persoane",
      content: "",
      icon: require("./svg/square-6.svg")
    },
  }
};

export default initialData;
